import request from "@/utils/request";

//查询疑难问题列表
export function getQuestionList(data) {
  return request({
    url: `api/tmProblemQuery/list?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
    method: 'post',
    data
  })
}
//查询疑难问题详情
export function getQuestionDetail(data) {
  return request({
    url: `api/tmProblemQuery/getInfoById?id=${data.id}`,
    method: 'get',
  })
}

//提问
export function subQuestion(data) {
  return request({
    url: `api/tmProblemQuery/add`,
    method: 'post',
    data
  })
}
//修改解决状态
export function updateIsSolve(data) {
  return request({
    url: `api/tmProblemQuery/updateIsSolve`,
    method: 'post',
    data
  })
}
//回复提问
export function saveReply(data) {
  return request({
    url: `api/tmProblemReply/add`,
    method: 'post',
    data
  })
}
//查询回复列表
export function getReplyList(data) {
  return request({
    url: `api/tmProblemReply/list?id=${data.id}&pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
    method: 'get',
  })
}

//查询回复列表
export function getMyQuestionList(data) {
  return request({
    url: `api/tmProblemQuery/ownQuery?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
    method: 'post',
    data
  })
}
